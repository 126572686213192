html,
body {
    margin: 0px;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
}

.app {
    background-color: white;
    display: grid;
    height: 100%;
    width: 100%;

    .content {
        padding: 25px;
        line-height: 1.5;
        flex-grow: 1;
        background-color: white;

        h2 {
            font-size: 30px;
            margin-top: 0px;
            margin-bottom: 20px;
        }
    }

    .container {
        height: 100%;
        flex-direction: column;
        display: flex;
    }

    .layout-body {
        flex: 1;
        min-height: 0;
    }

    .navigation {
        background-color: white;
    }
}

.dx-checkbox-checked .dx-checkbox-icon {
    color: #fdc94a;
}

.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
    background: #355c3a !important;
}

.dx-radiobutton-icon:before {
    border: 1px solid #176943 !important;
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content {
    color: #355c3a;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
    background-color: #fef9ec;
    color: #71728c;
    border-color: #f4cb66;
}

.dx-widget {
    color: #355c3a;
    font-weight: 450;
}

.dx-tab.dx-tab-selected {
    font-weight: bolder;
}

.dx-tab-selected::after {
    border: #fdc94a 1px solid;
}

.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
    font-size: 16px;
    background-color: #fdc94a;
    color: white;
    border: none;
}

.dx-button {
    border-radius: 0 !important;
    font-weight: 600 !important;
}

.dx-button-mode-contained:disabled .dx-button-success:disabled {
    background-color: #707070;
}

.dx-tagbox {
    max-width: 100vh;
}
