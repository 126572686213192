.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f2f4f8;
}

.error-content {
    width: 80%;
    max-width: 400px;
    text-align: center;
    padding: 2rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    animation: slide-up 1.5s ease-in;
}

.error-header {
    margin-bottom: 1.5rem;
}

.error-heading {
    font-size: 1.8rem;
    font-weight: bold;
    color: #ff5a5f;
}

.error-text {
    font-size: 1rem;
    color: #333333;
}

.error-logo {
    width: 170px;
    height: 80px;
    margin-bottom: 1rem;
    display: block;
    margin: 0 auto;
}

.error-button {
    background: #ff5a5f;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease-in;
}

.error-button:hover {
    background: #355c3a;
}
.error-details {
    font-size: 0.8rem;
    margin-top: 1rem;
    color: #666666;
}

@keyframes slide-up {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
